// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import IntlTelInputWithUtils from 'intl-tel-input/react/build/IntlTelInputWithUtils.js';
import styles from './Locator.module.scss';
import 'intl-tel-input/styles';

import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { LookupDoneCallback } from '#types/Geo';
import api from '#src/api';
import Button from '#components/ui/Button';
import { visitPhoneData$ } from '#store/visitPhoneData';
import {
  findLostPhoneAnimationFlag$,
  locationByNumberAnimationFlag$,
  numberLookupAnimationFlag$,
} from '#store/initAnimation';
import { useStore } from '@nanostores/react';
import { loadingButton } from '#store/locate';
import { clearFeaturesStorage } from '#utils/clearFeaturesStorage';
import useResponsive from '#hooks/useResponsive';
import { saveCheckoutExtraData } from '#utils/saveCheckoutExtraData';
import analytics from '#utils/analytics';
import { numberLookup } from '#store/lookupData';

type Props = {
  error: string;
  buttonLabel: string;
  mobileSeparateButton?: boolean;
  icon?: string;
  buttonStyle?: string;
  animationType: string;
};

const Locator: FC<Props> = ({
  error = '',
  buttonLabel,
  mobileSeparateButton,
  icon = 'locate',
  buttonStyle,
  animationType,
}) => {
  const [phone, setPhone] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const $loadingButton = useStore(loadingButton);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const telInputRef = useRef<any>(null);
  const formRef = useRef<null | HTMLFormElement>(null);
  const isMobile = useResponsive('mobile');

  useEffect(() => {
    if (!valid && phone) {
      setMessage(error);
    }
    setMessage('');
  }, [phone]);

  useEffect(() => {
    if (telInputRef.current && isMobile) {
      telInputRef.current.getInput().addEventListener('focus', handleFocus);
      return () => {
        telInputRef.current.getInput().removeEventListener('focus', handleFocus);
      };
    }
  }, [telInputRef, isMobile]);

  const handleFocus = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const savePhoneToStorage = (phoneParam: string) => {
    const { pathname } = window.location;
    switch (true) {
      case pathname.includes('reverse-number-lookup'):
        sessionStorage.setItem('LookUpNumber', phoneParam);
        break;
      case pathname.includes('find-my-lost-phone'):
        sessionStorage.setItem('lostPhone', 'true');
        sessionStorage.setItem('LostPhoneNumber', phoneParam);
        break;
      default:
        sessionStorage.setItem('isMainPage', 'true');
        sessionStorage.setItem('phoneNumber', phoneParam);
    }
  };

  const initAnimation = (animationType: string) => {
    switch (animationType) {
      case 'locationByNumber': {
        locationByNumberAnimationFlag$.set(true);
        break;
      }
      case 'numberLookup': {
        numberLookupAnimationFlag$.set(true);
        break;
      }
      case 'findLostPhone': {
        findLostPhoneAnimationFlag$.set(true);
        break;
      }
      default:
        return false;
    }
  };

  const onSubmit = async (e: SyntheticEvent | KeyboardEvent): Promise<void> => {
    e.preventDefault();
    if (telInputRef.current) {
      telInputRef.current.getInput().blur();
    }
    if (valid && phone) {
      loadingButton.set(true);
      clearFeaturesStorage();
      savePhoneToStorage(phone);
      saveCheckoutExtraData(phone);
      numberLookup.set(phone);
      analytics.event({ action: 'Locate number' });
      const { data } = await api.visit.phone(phone);
      if (data) {
        visitPhoneData$.set(data);
        initAnimation(animationType);
      }
    } else {
      setMessage(error);
    }
  };

  return (
    <form
      className={`${styles.form} ${message ? styles.error : ''} ${mobileSeparateButton ? styles.sepButton : ''}`}
      ref={formRef}
      onSubmit={onSubmit}
    >
      <div className={styles.input}>
        <IntlTelInputWithUtils
          onChangeNumber={setPhone}
          onChangeValidity={setValid}
          ref={telInputRef}
          initOptions={{
            initialCountry: 'auto',
            separateDialCode: true,
            autoInsertDialCode: true,
            nationalMode: false,
            strictMode: true,
            geoIpLookup: (callback: LookupDoneCallback) => api.geo.lookup(callback),
          }}
        />
        {message && (
          <div className={`${styles.message} ${message ? styles.error : ''}`}>{message}</div>
        )}
      </div>
      <Button
        type="submit"
        text={buttonLabel.toUpperCase()}
        icon={icon}
        styleType="orange"
        isLoading={$loadingButton}
        customStyle={buttonStyle}
        onClick={onSubmit}
      />
    </form>
  );
};

export default Locator;
