export const saveCheckoutExtraData = (value: string) => {
  const getFeatureFromPath = () => {
    const { pathname } = window.location;
    switch (true) {
      case pathname.includes('ip-tracker'):
        return 'locationByLink';
      case pathname.includes('reverse-number-lookup'):
        return 'numberLookup';
      case pathname.includes('find-lost-phone'):
        return 'lostPhone';
      case pathname.includes('reverse-username-lookup'):
        return 'usernameLookup';
      default:
        return 'locationByNumber';
    }
  };

  if (value) {
    localStorage.setItem(
      'checkoutExtraData',
      JSON.stringify({ type: getFeatureFromPath(), value }),
    );
  } else {
    localStorage.setItem('checkoutExtraData', JSON.stringify({ type: getFeatureFromPath() }));
  }
};
